<template>
    <div id="app">
        <div class="main-box">
            <ve-line :data="dd" height="250px" :colors="colors" :settings="chartSettings"
                     :extend="percentExtend"></ve-line>
        </div>
        <div align="center">
            <img src="images\u395.png" width="90%"/>
        </div>
        <div v-bind:key="item.dt" v-for="item in dt"  style="margin-top: 10px">
            <div class="title1">{{item.dt}}</div>
            <div class="list_day2">
                <ul>
                    <li v-bind:key="it.tm" v-for="it in item.arr">
                        <div class="pc"><img src="img/xy.png" width="40px"></div>
                        <div class="txt1">
                            <span>{{it.tm}}</span>
                            <span style="color: orange;font-size: large">{{it.value}} mmHg</span>

                        </div>
                        <span style="float: right;margin-top: -3em">{{it.msg}}</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import VeLine from 'v-charts/lib/line.common'
    import 'v-charts/lib/style.css'


    export default {
        components: {VeLine},
        name: "Bloodhistory",
        created() {
            var olddt;
            var j = 0;
            for (var i = 0; i < window.blood.length; i++) {
                var arr = new Array();
                window.blood[i].time = new Date(window.blood[i].created_at.$date - 8 * 60 * 60 * 1000).Format('yy-MM-dd\nhh:mm:ss')
                if (i < 7) {
                    this.dd.rows.push(window.blood[i])
                }

                var dts = new Date(window.blood[i].created_at.$date - 8 * 60 * 60 * 1000).Format('MM月dd')
                var msg;
                if (window.blood[i].sbp<120){
                    msg ='正常血压'
                }else if(window.blood[i].sbp>=120 && window.blood[i].sbp<=139) {
                    msg ='正常高值'
                }else if(window.blood[i].sbp>=140 && window.blood[i].sbp<=159) {
                    msg ='轻度高血压'
                }else if(window.blood[i].sbp>=160 && window.blood[i].sbp<=179) {
                    msg ='中度高血压'
                }else if(window.blood[i].sbp>=180) {
                    msg ='重度高血压'
                }
                arr.push({'value': window.blood[i].dbp + '/' + window.blood[i].sbp, 'msg': msg, 'tm': new Date(window.blood[i].created_at.$date - 8 * 60 * 60 * 1000).Format('hh:mm')})
                if (olddt != dts) {
                    this.dt.push({'dt': dts, 'arr': arr})
                    olddt = dts;
                    j++;
                } else {
                    this.dt[j - 1].arr.push(arr[0])
                }
            }
            this.dd.rows.reverse()
        },
        data() {
            return {
                dd: {
                    columns: ['time', 'dbp', 'sbp'],
                    rows: []
                },
                dt: [],
                colors: ['#fc783a', '#bec3fa'],
                chartSettings: {
                    labelMap: {
                        'time': '日期',
                        'dbp': '舒展压',
                        'sbp': '收缩压'
                    },
                },
                percentExtend: {
                    series: {
                        label: {
                            show: true,
                        }
                    },
                    legend: {
                        bottom: 10
                    }
                },
            }
        },
        methods: {}
    }
</script>